body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: radial-gradient(
        circle farthest-side at 0% 50%,
        white 23.5%,
        transparent 0
      )
      21px 30px,
    radial-gradient(
        circle farthest-side at 0% 50%,
        rgba(220, 220, 240, 0.6) 24%,
        transparent 0
      )
      19px 30px,
    linear-gradient(white 14%, transparent 0, transparent 85%, white 0) 0 0,
    linear-gradient(
        150deg,
        white 22%,
        rgba(220, 220, 240, 0.6) 25%,
        transparent 28%,
        transparent 72%,
        rgba(220, 220, 240, 0.6) 75%,
        white 78%
      )
      0 0,
    linear-gradient(
        30deg,
        white 22%,
        rgba(220, 220, 240, 0.6) 25%,
        transparent 28%,
        transparent 72%,
        rgba(220, 220, 240, 0.6) 75%,
        white 78%
      )
      0 0,
    linear-gradient(
        90deg,
        rgba(220, 220, 240, 0.6) 3%,
        white 4%,
        white 97%,
        rgba(220, 220, 240, 0.6) 100%
      )
      0 0 white;
  background-size: 40px 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background: linear-gradient(5deg, white 20%, transparent);
}

@keyframes newestPlayer {
  from {
    outline: 0px solid #fcd34d;
  }
  50% {
    outline: 12px solid #fcd34d;
  }
  to {
    outline: 0px solid #fcd34d;
  }
}
